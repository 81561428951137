
import { communicationMapActions } from "@/store/modules/communication";
import Vue from "vue";
export interface IData {
  loading: boolean;
  metrics: Record<string, any>;
}
export default Vue.extend({
  name: "CommunicationEmailMetrics",
  components: {
    EmailMetricsCard: () => import("./EmailMetricsCard.vue")
  },
  created(): void {
    this.fetchCommunicationMetrics();
  },
  props: {
    communicationId: {
      type: String,
      required: true,
      default: ""
    }
  },
  data(): IData {
    return {
      loading: false,
      metrics: {}
    };
  },
  methods: {
    ...communicationMapActions(["getCommunicationMetrics"]),
    async fetchCommunicationMetrics(): Promise<void> {
      try {
        this.loading = true;
        const response = await this.getCommunicationMetrics(
          this.communicationId
        );

        if (response.data && response.data.general) {
          this.metrics = response.data.general;
        }
      } catch (error) {
        this.$appNotifyError("Failed to fetch communication recipients data");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    }
  }
});
